@import url('https://fonts.googleapis.com/earlyaccess/opensanshebrew.css');
@import url('https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
@import 'react-alice-carousel/lib/alice-carousel.css';

body {
  color: #ffff !important;
}

/* chakra-select */
.login:-webkit-autofill,
.login:-webkit-autofill:hover,
.login:-webkit-autofill:focus,
.login:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  font-size: 18px !important;
}

.chakra-switch__track {
  background: rgba(120, 120, 128, 0.32) !important;
}

.recharts-layer div {
  white-space: nowrap;
}

.chakra-toast__inner .chakra-alert .chakra-alert__icon {
  margin-right: 15px !important;
}

.chakra-checkbox {
  border-radius: 5px;
}

tr {
  height: 48px !important;
}

.css-udkdas tr:nth-of-type(odd) td,
td {
  border-color: white !important;
}

th,
tr {
  border-color: #e0e9f2 !important;
}
thead {
  border: none !important;
}

.pac-container.hdpi.pac-logo:after {
  content: none !important;
}

.chakra-checkbox__control {
  border-radius: 5px !important;
  border: 1px solid !important;
  border-color: #bdbdbd !important;
}
.chakra-checkbox__control[data-checked] {
  border-color: #4990d2 !important;
}

.chakra-switch__track[aria-checked='true'],
.chakra-switch__track[data-checked] {
  background: #4990d2 !important;
}

.chakra-select__wrapper .chakra-select {
  padding: 8px;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
}

.chakra-select__wrapper .chakra-select__icon-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-left: 10px;
}

/* end chakra-select */

/* react-calendar */
.react-calendar__navigation__arrow {
  display: none;
}

.react-calendar {
  border: none !important;
  max-width: 280px;
  height: 280px;
  z-index: 11;
  margin: 0 auto;
}

.react-calendar__navigation__label__labelText {
  text-transform: uppercase;
  color: black;
}

.react-calendar__viewContainer {
  max-width: 280px;
  margin: 0 auto;
}

.react-calendar abbr[title] {
  text-decoration: none;
  color: #891308;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  text-transform: capitalize !important;
}

.react-calendar__month-view__days abbr {
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  color: black;
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: transparent !important;
}

.react-calendar__tile--now {
  background: none !important;
}

.react-calendar__tile--active {
  background: transparent !important;
  color: black !important;
  position: relative;
  z-index: 111;
}

.react-calendar__tile--active::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #4990d2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* end react-calendar */

.header-md::after {
  height: 0;
}

.btnEllipse {
  border: 1px solid #891308 !important;
}
.btnEllipse:focus,
.btnEllipse[data-focus] {
  box-shadow: none !important;
}

.progressBar div {
  background-image: linear-gradient(90deg, #6e1105 50%, #891308 50%);
  background-size: 2rem 2rem;
}
.borderGradient {
  background-image: linear-gradient(90deg, #6e1105 50%, #891308 50%) !important;
  background-size: 2rem 2rem !important;
}

#activeButton .value .active {
  background: red !important;
}
.chakra-modal__content {
  margin: 0 15px;
  max-width: 350px;
  height: 350px;
  border-radius: 15px !important;
  border: 1px solid #797983;
}

.productBox {
  border: 1px solid #797983 !important;
}

#chakra-modal-4 button:active,
#chakra-modal-4 button[data-active],
#chakra-modal-4 button:focus,
#chakra-modal-4 button[data-focus] {
  /* background: transparent !important; */
  box-shadow: none !important;
}

/* .chakra-switch__track {
  background: transparent !important;
  box-shadow: none !important;
} */

.chakra-button.pagination-separator {
  color: #4990d2;
}

.red .chakra-switch__thumb,
.yellow .chakra-switch__thumb {
  background: #4281bd !important;
}

.blue .chakra-switch__thumb {
  background: #891308 !important;
}

.chakra-switch__thumb {
  z-index: 22;
}
.marginBottom {
  margin-bottom: 0 !important;
}

.radioCheckbox {
  -webkit-margin-start: 0 !important;
  margin-inline-start: 0 !important;
}
.circleGraphTooltip.recharts-default-tooltip {
  padding: 0px !important;
  border: none !important;
  background: transparent !important;
}
.chartBackground {
  position: relative;
}
.chartBackground::after {
  content: '';
  display: block;
  width: calc(100% - 60px);
  height: calc(100% - 40px);
  position: absolute;
  right: 60px;
  bottom: 40px;
  background-image: url('../Images/PNG/gridBase.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* responsive style */

@media screen and (min-height: 765px) {
  .wrapTableModal {
    height: 290px;
  }
}

@media screen and (max-height: 764px) {
  .wrapTableModal {
    height: 150px;
  }
}

.chartBar2 {
  animation: animChartBar 1s ease-in-out;
}

.chartBar3 {
  animation: animChartBar 1.3s ease-in-out;
}

.chartBar4 {
  animation: animChartBar 1.1s ease-in-out;
}

.chartBar5 {
  animation: animChartBar 1.5s ease-in-out;
}

.chartBar6 {
  animation: animChartBar 1.7s ease-in-out;
}

@keyframes animChartBar {
  from {
    transform: translateY(500px);
    opacity: 0.4;
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.inputFocus:focus .centerFocus {
  border: 1px solid red;
}

.react-datepicker {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', sans-serif;
  overflow: hidden;
  z-index: 999;
  padding-top: 100px !important;
  display: flex !important;
  justify-content: center !important;
  border-radius: 40px !important;
  border: none !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3) !important;
}
.react-datepicker-popper {
  z-index: 99999999 !important;
  height: 525px;
  width: 380px;
  border-radius: 40px !important;
}

.react-datepicker-popper div :nth-child(0),
.react-datepicker {
  height: 525px;
  width: 380px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 8px;
  margin-top: 85px;
}

.react-datepicker__navigation--previous {
  border-right-color: white;
  left: 55px !important;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: white;
}

.react-datepicker__navigation--next {
  border-left-color: white;
  right: 55px !important;
}

.react-datepicker__navigation--next:hover {
  border-left-color: white;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}
/* .react-datepicker__input-container input {
  padding-right: 5px;
} */
/* .react-datepicker__input-container input::placeholder {
  padding-right: 15px;
} */

.react-datepicker__header {
  border-radius: 0;
  background: white !important;
  border: none !important;
}

.react-datepicker,
.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e2e8f0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: normal !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
  color: black !important;
  margin-top: 15px !important;
}

.react-datepicker__day-names .react-datepicker__day-name {
  font-family: 'Poppins', sans-serif !important;
  color: #891308 !important;
  font-weight: 700 !important;
  font-size: 12px !important;

  margin-top: 40px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: #edf2f7;
}

.react-datepicker__day:hover {
  background: #edf2f7;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #3182ce;
  font-weight: normal;
  border-radius: 50% !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: #2a69ac;
  border-radius: 50% !important;
}

.dateInput {
  height: 36px;
  padding: 8px;
  margin: 0px;
  /* padding-right: 20px; */
  width: 120px;
  font-size: 14px;
  border: 1px solid #e5e5e5;
  outline: none;
  border: none;
  border-radius: 0 10px 10px 0;
  border-left: none;
  background: none;
  color: #161621;
  font-size: 12px;
}

.dateInput.range {
  width: 170px;
  padding-right: 5px;
}
.dateInput.range::placeholder {
  padding-right: 15px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 7px !important;
  font-weight: bold !important;
}

.react-datepicker__month {
  border-radius: 8px !important;
  margin-top: 20px !important;
}
.react-datepicker__day--keyboard-selected {
  background: #4990d2 !important;
  border-radius: 50% !important;
}
.react-datepicker__day--selected {
  background: #4990d2 !important;
  border-radius: 50% !important;
}

.inputMaskTime {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.recharts-surface {
  overflow: visible;
}

.alice-carousel__stage-item {
  width: auto !important;
}

chakra-modal__content-container::-webkit-scrollbar {
  width: 5px !important;
  border-radius: 4px !important;
}

chakra-modal__content-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

chakra-modal__content-container::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  outline: 1px solid slategrey !important;
  width: 6px;
  border-radius: 4px;
}

.recharts-legend-item-text {
  padding-right: 8px;
  margin-top: 2px;
  color: black !important;
}
